.question-container {
  background-color: #f7f7f7; /* Fondo gris claro */
  padding: 30px; /* Aumentar el relleno */
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px auto; /* Centrar el contenedor */
  max-width: 800px; /* Ancho máximo aumentado */
  width: 100%;
}
/* Esto es para centrar la tarjeta de la pregunta */
.question-card-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 100px); /* Ajusta este valor para dejar espacio para otros elementos si es necesario */
  width: 100%; /* Asegúrate de que el contenedor se extienda a lo ancho */
}
.question-statement {
  color: #333333;
  text-align: center; /* Centrar la declaración */
  margin-bottom: 20px;
  background-color: #004466; /* Fondo azul oscuro como en la imagen */
  color: white; /* Texto blanco */
  padding: 15px; /* Aumentar el relleno */
  border-radius: 5px;
  font-size: 1.2rem; /* Aumentar el tamaño de la fuente para la declaración */
}

.options-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); /* Crea una cuadrícula responsiva */
  gap: 15px; /* Aumentar espacio entre opciones */
  margin-top: 20px;
}

.option-button {
  background-color: white;
  color: #004466; /* Texto azul oscuro */
  padding: 15px; /* Aumentar el relleno */
  border: 2px solid #004466; /* Borde azul oscuro */
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s;
  font-size: 1rem; /* Ajustar el tamaño de la fuente de las opciones si es necesario */
}

.option-button:hover {
  background-color: #004466; /* Fondo azul oscuro al pasar el mouse */
  color: white; /* Texto blanco al pasar el mouse */
}

.test-instructions {
  background-color: #ffffff; /* Fondo claro para resaltar las instrucciones */
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra suave para un efecto elevado */
}

.test-instructions p {
  font-size: 1.2rem; /* Tamaño de fuente aumentado para las instrucciones */
  font-weight: bold; /* Negrita para resaltar */
  color: #333; /* Color de texto oscuro */
  margin: 0; /* Eliminar márgenes predeterminados */
  text-align: left; /* Alineación de texto a la izquierda */
}

.navigation-buttons-container {
  display: flex;
  justify-content: flex-start; /* Centra el botón en la izq */
  margin-top: 20px; /* Espacio arriba del botón */
}

.prev-button {
  background-color: #ffffff; /* O el color que prefieras */
  color: #004466; /* Color de texto */
  padding: 10px;
  border: 2px solid #004466; /* Borde */
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s;
  font-size: 1rem; /* Tamaño de la fuente */
  /* Otros estilos para que coincida con los botones de opción */
}

.prev-button:hover {
  background-color: #004466; /* Cambiar el fondo al pasar el mouse */
  color: white; /* Cambiar el texto al pasar el mouse */
  
}
