/* AutocompleteInput.css */
.autocomplete-input {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
}

.autocomplete-input label {
  display: block;
  font-size: 12px;
  color: #333; /* Darker color for better contrast */
  font-weight: bold; /* Makes text more visible */
  margin-bottom: 5px;
}

.autocomplete-input input {
  width: 100%;
  padding: 15px;
  border-radius: 5px;
  border: 1px solid gray;
  font-size: 16px;
  box-sizing: border-box;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  max-height: 80vh;
  overflow-y: auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.modal-header h3 {
  margin: 0;
}

.modal-header button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.modal-content input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.options-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  max-height: 300px;
  overflow-y: auto;
}

.options-list li {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.options-list li:hover,
.options-list li.active {
  background-color: #f0f0f0;
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

.options-list li mark {
  background-color: yellow;
  padding: 0;
}

.ComenzarP {
  background-color: #0056b3; /* Darker blue for better contrast */
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

/* Add more contrast adjustments as needed */