select {
    padding: 15px;
    margin: 10px 0;
    border-radius: 5px;
    border: 1px solid #ccc; /* Color de borde más suave */
    background-color: white;
    font-size: 16px;
    transition: border-color 0.3s, box-shadow 0.3s;

    /* Quitar estilos por defecto en algunos navegadores */
    -webkit-appearance: none;  
    -moz-appearance: none;
    appearance: none;
}

/* Estilo para el enfoque en select */
select:focus {
    border-color: #004466; /* Mismo color que el botón para el enfoque */
    box-shadow: 0 0 5px rgba(0, 68, 102, 0.5); /* Sombra suave para un efecto de "glow" */
    outline: none; /* Quitar el contorno por defecto en foco */
}

/* Para el icono de desplegable, puedes agregar un pseudo-elemento */
.formInput select {
    position: relative;
}

.formInput select:after {
    content: '▼'; /* Icono de flecha hacia abajo */
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    pointer-events: none;
    color: #004466; /* Color del texto del botón */
}


/**/
.formInput {
    display: flex;
    flex-direction: column;
    width: 100%; /* Ajusta el ancho como prefieras */
    margin-bottom: 20px; /* Espacio entre los campos del formulario */
}
.formInput span {
    transition: opacity 0.3s ease, max-height 0.3s ease;
    max-height: 0;
    overflow: hidden;
}

.formInput input:invalid[focused="true"] ~ span,
.formInput select:invalid[focused="true"] ~ span {
    max-height: 50px; /* Altura máxima para mostrar el mensaje */
    opacity: 1;
}

input, select {
    padding: 1em; /* Usa unidades relativas */
    margin: 10px 0;
    border-radius: 5px;
    border: 1px solid gray;
    font-size: 1rem; /* Tamaño de fuente relativo */
}

label{
    font-size: 12px;
    color: gray;
}

span{
    font-size: 12px;
    padding: 3px;
    color: red;
    display: none;
}

input:invalid[focused="true"]{
    border: 1px solid red;
}

input:invalid[focused="true"] ~ span{
    display: block;
}

/* Media queries para diferentes tamaños de pantalla */
@media (max-width: 600px) {
    .formInput {
        margin-bottom: 15px;
    }

    input, select {
        padding: 0.8em;
        font-size: 0.9rem;
    }

    label, span {
        font-size: 0.8rem;
    }
}